import React from 'react'
import PrimaryButton from '../components/PrimaryButton'
import './OrderSummaryPage.css'

function OrderSummaryPage() {
  return (
    <div className='order-container'>
        <h2>Order Summary</h2>
        <div className='input-container'>
            <div className='summary-div'>
                <div className='quan'><p>Ticket</p><p>N 2500</p></div>
                <div className='quan'><p>Quantity</p><p>1</p></div>
                <div className='total'>
                    <p>Total</p>
                    <p>N 2500</p>
                </div>
            </div>
        </div>
            <div className='ticket-button'>
                <PrimaryButton style={{width:'270px', marginBottom: '30px'}}>
                    Pay now
                </PrimaryButton>
            </div>
    </div>
  )
}

export default OrderSummaryPage
