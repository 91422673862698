import React, { useContext, useState } from 'react'
import { PaystackButton } from 'react-paystack'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../components/PrimaryButton'
import { UserContext } from '../context/UserContext'
import './ChooseTicketPage.css'
import axios from 'axios'
import logo1 from '../full logo orange.png'



function NonComputer() {
    const publicKey = "pk_live_407ef78d9a211465ce1b42e01d6d4ee20f08433c"
    const amount = 200000
    const [email, setEmail] = useState("")
    const [fullName, setFullName] = useState("")
    const [matricNo, setMatricNo] = useState("")
    const [phone, setPhone] = useState("")
    const [level, setLevel] = useState("")
    const userCtx = useContext(UserContext)
    let navigate = useNavigate()
    const handlePaystackPayment = async (reference) => {
        // Use the Paystack API to initiate payment and get the reference ID
        // Set the reference ID in the state of your component or pass it as a prop to other components
        alert("Thank you for purchasing your ticket, see you at the dinner!!")
        navigate('/ticket')
        console.log(reference.reference)
        await userCtx.payment(reference.reference)
      
      }
    const componentProps = {
        email,
        amount:amount + 7000,
        metadata: {
            fullName,
            matricNo,
          phone,
          uid:'644872723c614042a77de9d1',
          number:1
        },
        publicKey,
        text: "Pay Now",
        onSuccess: (reference) =>{
          handlePaystackPayment(reference)
        },

        onClose: () => {
            alert("Wait! You need this ticket, don't go!!!!")
            navigate('/buy/non-computer')
        },
      }
      async function submitHandler(e){
        e.preventDefault()
        try {
            if(fullName === " " || matricNo === " " || (email === " "|| !email.includes('@')) || phone === ""){
                alert('Please provide the right information')
                
            }
            // const response = await axios.post(`${apiUrl}/api/v1/user/resendverification`, JSON.stringify({email:email}),   {
            //     headers: {
            //       "Content-Type": "application/json",
            //       "Access-Control-Allow-Origin": "https://trashgo.onrender.com", // replace with your own domain
            //     },
            //     mode: "cors",
            //     credentials: "include",
            //   })
            //   console.log(response)
            await userCtx.setUserData({fullName,matricNo,email,phone,amount:amount/100})
            console.log(userCtx)
        } catch (error) {
            console.log(error.response)
        }
      }
  return (
    <div className='ticket-container'>
        <img src={logo1} width='100' height='50' style={{margin: '20px auto'}}/>
        <hr/>
        <h2>Pay for your ticket</h2>
        <div className='ticket-date'>
            <p>Sunday, May 7 2023</p>
        </div>
        <div className='input-container'>
            <div className='ticket-info'>
                <span>Ticket</span>
                <p>₦ {amount / 100}</p>
                <span>Get your tickets to have a blast </span>
            </div>
            <div className='form-div'>
            <form className='form' onSubmit={submitHandler}>
                <div  className='inputHolder'>
                    <label>Full Name</label>
                    <input type={'text'} id="first" onChange={(e)=>setFullName(e.target.value)} placeholder='Enter your Full Name'/>
                </div>
                <div  className='inputHolder'>
                    <label>Matric No</label>
                    <input type={'text'} id="last" onChange={(e)=>setMatricNo(e.target.value)} placeholder='Enter your last name'/>
                </div>
                <div  className='inputHolder'>
                    <label>Email address</label>
                    <input type={'email'} id="email" onChange={(e)=>setEmail(e.target.value)} placeholder='Enter your email address'/>
                </div>
                <div  className='inputHolder'>
                    <label>Phone number</label>
                    <input type="text" id="phone" onChange={(e) => setPhone(e.target.value)} placeholder='Enter your phone number'/>
                </div>
                <div className='ticket-button'>
                <PaystackButton className='paystack-button' {...componentProps}/>
                </div>
            </form>
            </div>
        </div>
    </div>
  )
}

export default NonComputer
