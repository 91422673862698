import React from 'react'
import './PrimaryButton.css'

function PrimaryButton({children,style,onClick}) {
  return (
    <div className='btn-container'>
      <button onClick={onClick} type='button' className='button' style={style}>
        {children}
      </button>
    </div>
  )
}

export default PrimaryButton
