import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import DetailPage from './page/DetailPage';
import ChooseTicketPage from './page/ChooseTicketPage';
import OrderSummaryPage from './page/OrderSummaryPage';
import TicketPage from './page/TicketPage';
import UserProvider from './context/UserContext';
import NonComputer from './page/NonComputer';

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div style={{ backgroundColor: '#FFFFFF', color: '#000000', height: '100vh', display:'flex', justifyContent: 'center', alignItems: 'center' }}>
      {!isMobile? 
      <div>
          <h1 style={{textTransform: 'capitalize', opacity: 0.6}} >This website is view on mobile only</h1>
      </div>
      :
    <UserProvider>
    <Routes>
      <Route path='/' element={<DetailPage/>} />
      <Route path='/buy/computer' element={<ChooseTicketPage/>} />
      <Route path='/buy/non-computer' element={<NonComputer/>} />
      <Route path='/order' element={<OrderSummaryPage/>} />
      <Route path='/ticket' element={<TicketPage/>} />
    </Routes>
    </UserProvider>
      }
    </div>
    // <div>
    //   {!showMobileWarning && <div>Sorry, this website is only available on desktop devices.</div>}
  
    // </div>
  );
}

export default App;
