import { createContext, useState } from "react";

export const UserContext = createContext(null)

function UserProvider({children}){
    const [userInfo, setUserInfo] = useState( JSON.parse(sessionStorage.getItem('userInfo')) || {})
    const [paymentInfo, setPaymentInfo] = useState( JSON.parse(sessionStorage.getItem('paymentInfo')) || {})

    function setUserData(user){
        setUserInfo(user)
        sessionStorage.setItem('userInfo', JSON.stringify(user))
    }
    function payment(pay){
        setPaymentInfo(pay)
        sessionStorage.setItem('paymentInfo', JSON.stringify(pay))
    }

    const value = {
        userInfo:userInfo,
        setUserData:setUserData,
        paymentInfo:paymentInfo,
        payment:payment,
    }

    return <UserContext.Provider value={value}>
        {children}
    </UserContext.Provider>
}

export default UserProvider