import React, { useContext, useRef, useState } from 'react'
import './PaymentModal.css'
import PrimaryButton from './PrimaryButton'
import { VscClose } from 'react-icons/vsc'
import { useNavigate } from "react-router-dom";

// pk_test_e4d7d1e2ca71e9885dc1ab00387e876bb2ddc7fe
const options =[
    {label: "Computer Science", value:"computerscience" },
    {label: "Non-Computer Science", value:"non-computerscience"}
]
function PaymentModal({onOpen,setModal}) {

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    // const email = userCtx.userInfo.email
    // const name = `${userCtx.userInfo.firstName} ${userCtx.userInfo.lastName}`
    // const phone = userCtx.userInfo.phoneNumber
    const amount = 1000000
    const [selectedOption, setSelectedOption] = useState(null)
    const [optionValue, setOptionValue] = useState('')
    let navigate = useNavigate()
    const handleChange = (event) => {
        setSelectedOption(event.target.value)
        // setSelectedOption(event.target.value);
        // setOptionValue(event.target.value)
      };
    function handleClose(){
       setModal(false)
    }


    function handleSubmit(e){
        e.preventDefault()
        console.log("optionValue")
        // if(selectedOption === 'computerscience'){
        //     console.log('uuu')
        //     navigate('/buy/computer')
        // } else{
        //     navigate('/buy/non-computer')

        // }
    }
  return (
    <div className='paymentmodal-container' >
        <div className='paymentmodal-div'>
        <VscClose className='close-icon' onClick={handleClose} />
            <div className='paymentmodal-div-service'>
                {/* <form onSubmit={handleSubmit}>
                {options.map((option) => (
                <div className='radioHolder' key={option.value}>
                    <div>
                <label htmlFor={option.value} >
                {option.label}
                </label>
                    </div>
                <input
                id={option.value}
                type="radio"
                name="options"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={handleChange}
                />
                </div>
                ))} */}
                {/* <div className='checkboxHolder'>
                    <input type={'checkbox'} />
                    <label>I agree to the Terms of Service</label>
                </div> */}
                <h2 style={{textAlign: 'center', marginBottom: '20px'}}>Choose Ticket</h2>
                <hr/>
                <div className='button-type'>
                    <PrimaryButton onClick={()=>navigate('/buy/non-computer')}  otherStyle={{height: '35px', padding:0, fontStyle: '14px', fontWeight: 'bold', borderRadius:'10px'}} style={{width: '260px'}} >
                    Non-Computer Science ₦2,000
                    </PrimaryButton>
                </div>
                <div className='button-type'>
                    <PrimaryButton onClick={()=>navigate('/buy/computer')} otherStyle={{height: '35px', padding:0, fontStyle: '14px', fontWeight: 'bold', borderRadius:'10px'}} style={{width: '260px'}} >
                    Computer Science ₦1,000
                    </PrimaryButton>
                </div>
                <div className='button-type'>
                    <PrimaryButton onClick={()=>navigate('/buy/computer')} otherStyle={{height: '35px', padding:0, fontStyle: '14px', fontWeight: 'bold', borderRadius:'10px'}} style={{width: '260px'}} >
                    Software Engineering ₦1,000
                    </PrimaryButton>
                </div>
                {/* </form> */}
            </div>
        </div>
    </div>
  )
}

export default PaymentModal