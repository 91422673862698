import React, { useState } from 'react'
import PrimaryButton from '../components/PrimaryButton'
import './DetailPage.css'
import imageBack from '../image2.JPG'
import { useNavigate } from 'react-router-dom'
import PaymentModal from '../components/PaymentModal'

function DetailPage() {
    let navigate = useNavigate()
    let [isOpen, setIsOpen] = useState(false)

    function handleModal(){
        setIsOpen(true)
    }
  return (
    <div className='detail-container'>
        <div className='img-container'>
            <img src={imageBack} />
        </div>
        <div className='detail-container-middle'>
            <div className='info-div'>
            <h3>Details</h3>
               <div className='info'>
                <p>Sunday, May 7 2023</p>
               </div>
               <div className='info'>
                <p>7:00 PM - 11:00 PM WAT</p>
               </div>
               <div className='info'>
                <p>M P H</p>
               </div>
            <div className='about'>
                <h3>About this event</h3>
                <p>My dear readers. I am delighted to inform you of an upcoming affair that is sure to delight the senses.
                    A Bridgerton-themed dinner is in the works, set to transport guests to the refined and
                    sophisticated world of Regency Nacoss. This is sure to be an evening that no Bridgerton fan will want to miss.
                </p>
            </div>
            </div>
            <div className='button-show'>
                <PrimaryButton onClick={handleModal}>
                    Buy Ticket
                </PrimaryButton>
            </div>
            {isOpen && <PaymentModal onOpen={isOpen} setModal={setIsOpen} />}
        </div>
    </div>
  )
}

export default DetailPage
