import React, { useContext, useEffect, useRef, useState } from "react";
import "./TicketPage.css";
import imageBack from "../image2.JPG";
import { UserContext } from "../context/UserContext";
import { FaDownload } from "react-icons/fa";
import html2pdf from "html2pdf.js";
import QRCode from "react-qr-code";
import { v4 as uuidv4 } from "uuid";

function TicketPage() {
  const userCtx = useContext(UserContext);
  const ticketRef = useRef(null);
  const [isScanned, setIsScanned] = useState(false);
  useEffect(() => {
    alert('Download the ticket via the download button')
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ''; // This is required for Chrome
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  // return (
  //   <img src={imageUrl} alt="Generated image" />
  // );

    // Generate the URL for the image here
    // For example, you could use a library like `url-builder` to create a URL with random parameters
  // const qrCodeData = `${userCtx.userInfo.firstName},${userCtx.userInfo.lastName}`;
  const qrCodeData = userCtx.paymentInfo
  console.log(qrCodeData)
  const transactionDate = new Date().toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const transactionTime = new Date().toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
  });
  // console.log('Transaction date:', transactionTime);
  // console.log('Transaction date:', transactionDate);
  useEffect(() => {
    console.log(ticketRef.current.innerHTML);
    console.log(ticketRef);
  }, []);
  function handleDownload() {
    const element = document.getElementById("ticket-body"); //replace 'content' with the id of your screen content
    const opt = {
      margin: 1,
      filename: "ticket.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().set(opt).from(element).save();
  }
  return (
    <div className="ticket-page-container">
      <div className="ticket-page-div" id="ticket-body">
        <div className="ticket-page-img">
          <img src={imageBack} alt="ticket" />
        </div>
        <div className="ticket-content">
          <div className="ticket-header">
            <div className="content">
              <h4>MET GALA</h4>
              <p>By COMPUTER SCIENCE</p>
            </div>
            <div className="icon">
              <FaDownload
                onClick={handleDownload}
                type="button"
                style={{ marginTop: "15px" }}
              />
            </div>
          </div>

          <div className="ticket-body" ref={ticketRef}>
            <div className="content-des">
              <div className="info-des">
                <span>Location</span>
                <p>Veritas University <br/>MPH Hall</p>
              </div>
              <div className="info-des">
              <QRCode
                size={76}
                style={{ height: "", maxWidth: "100%", width: "100%" }}
                value={qrCodeData}
                viewBox={`0 0 256 256`}
              />
           
              </div>
              <div className="info-des">
                <span>Name</span>
                <p>
                  {userCtx.userInfo.fullName}
                </p>
              </div>
              <div className="info-des">
                <span>Date</span>
                <p>{transactionDate}</p>
              </div>
              <div className="info-des">
                <span>Time</span>
                <p>{transactionTime}</p>
              </div>
              <div className="info-des">
                <span>Amount Paid</span>
                <p>₦ {userCtx.userInfo.amount}</p>
              </div>
            </div>
            <div
              style={{
                height: "auto",
                // margin: "30px auto",
                maxWidth: 64,
                width: "100%",
              }}
            >
              <div className="info-des descr">
                   <span>ID</span>
                <p>{userCtx.paymentInfo}</p>
              </div>
              <div className="info-des descr">
                   <span>Matric Number</span>
                <p>{userCtx.userInfo.matricNo}</p>
              </div>
            
              {/* <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={qrCodeData}
                viewBox={`0 0 256 256`}
              /> */}
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketPage;
